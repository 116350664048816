import React, { Component } from 'react'
import Link from 'gatsby-link'
import styled from '@emotion/styled'
import { colors } from '../styles/variables'
import { media } from '../styles/minWidth'
import Wrapper from '../styles/Wrapper'
import LogoImage from '../images/Logo'

const borderSize = 1
const height = {
  small: 60,
  large: 80,
}

const NavigationBar = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-size: 1.125em;
  font-weight: 500;
  position: relative;
  z-index: 2;

  ${props =>
    props.useBorder &&
    `
    &::after {
      background: ${colors.grey.extraLight};
      content: '';
      display: block;
      height: ${borderSize}px;
      width: 100%;
    }
  `};
`

const Inner = styled(Wrapper)`
  align-items: center;
  display: flex;
  height: ${height.small}px;

  ${media.navigation} {
    height: 80px;
    justify-content: space-between;
  }
  ${props =>
    !props.hasBackground &&
    `
    &::before {
      background: white;
      content: '';
      height: ${height.small}px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;

      ${media.navigation} {
        display: none;
      }
    }
  `};
`

const HeaderItem = styled.div`
  display: flex;

  > a {
    align-items: center;
    display: flex;
    width: 100%;

    ${media.navigation} {
      justify-content: center;
    }
  }
`

const headerLink = `
  color: ${colors.brown};
  text-decoration: none;

  &:hover {
    color: ${colors.primary};
  }
`

const MenuItem = styled(HeaderItem)`
  text-transform: lowercase;

  > a {
    ${headerLink};
  }
`

const RegularMenuItem = styled(MenuItem)`
  display: none;
  flex-basis: 100%;
  order: ${props => props.order};
  position: relative;

  ${props =>
    props.hasBackground &&
    `
    > a {
      color: white;
    }
  `} ${media.navigation} {
    display: flex;
    height: ${height.large}px;

    > div {
      display: none;
    }

    &:hover > div {
      display: block;
    }
  }
`

const Logo = styled(HeaderItem)`
  flex-basis: 210%;
  order: 3;

  svg {
    height: 35px;
    width: 110px;

    ${media.navigation} {
      height: auto;
      width: auto;
    }
  }
`

const transition = 'transform 0.3s cubic-bezier(0, 0, 0, 1)'

const Toggle = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: space-between;
  height: 19px;
  max-width: 24px;
  order: 6;
  transition: ${transition};

  ${media.navigation} {
    display: none;
  }

  div {
    background: ${props => (props.hasBackground ? 'white' : colors.brown)};
    border-radius: 3px;
    height: 3px;
    transition: ${transition};
    width: 100%;

    &:nth-of-type(1) {
      transform-origin: 100% 0;
    }

    &:nth-of-type(2) {
      transition-duration: 0.2s;
    }

    &:nth-of-type(3) {
      transform-origin: 100% 100%;
    }

    ${props =>
      props.toggled &&
      `
      &:nth-of-type(1) {
        transform: rotate(-45deg);
      }

      &:nth-of-type(2) {
        transform: scale(0, 1);
      }

      &:nth-of-type(3) {
        transform: rotate(45deg);
      }
    `};
  }
`

const ToggleMenu = styled.div`
  background: white;
  border-bottom: 1px solid ${colors.grey.extraLight};
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  transition: transform 0.3s cubic-bezier(0, 0, 0, 1);
  width: 100%;
  z-index: -2;

  ${props =>
    props.toggled &&
    `
    opacity: 1;
    transform: translateY(${height.small + borderSize}px);
  `} ${media.navigation} {
    display: none;
  }
`

const hoverableMenuItem = `
  display: block;
  padding: 14px 20px;

  &:hover {
    background: ${colors.orange};
  }
`

const ToggleMenuItem = styled(MenuItem)`
  flex-direction: column;

  > a {
    ${hoverableMenuItem} ${media.medium} {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  > div a {
    padding-left: 30px;

    ${media.medium} {
      padding-left: 60px;
    }
  }
`

const arrowWidth = 14

const Submenu = styled.div`
  ${media.navigation} {
    background: white;
    border: ${borderSize}px solid ${colors.grey.extraLight};
    border-radius: 3px;
    left: 50%;
    padding: 10px 0;
    position: absolute;
    text-align: left;
    transform: translateX(-50%);
    top: ${height.large - 10}px;
    width: 260px;

    &::after,
    &::before {
      border-color: transparent transparent ${colors.grey.extraLight}
        transparent;
      border-style: solid;
      border-width: 8px ${arrowWidth / 2}px;
      content: '';
      margin-left: -${arrowWidth / 2}px;
      left: 50%;
      position: absolute;
      top: 0;
      transform: translateY(-100%);
    }

    &::after {
      border-bottom-color: white;
      top: 1px;
    }

    > a {
      ${hoverableMenuItem + headerLink};
    }
  }
`

export default class Header extends Component {
  state = {
    toggled: false,
  }

  toggleNavigation = () => {
    this.setState({ toggled: !this.state.toggled })
  }

  render() {
    const items = [
      { label: 'Home', path: '/' },
      {
        label: 'Over Friska',
        path: '/over-friska',
        children: [
          { label: 'Over mij', path: '/over-friska' },
          { label: 'Specialisaties', path: '/specialisaties' },
          { label: 'Tarieven & vergoedingen', path: '/tarieven-vergoedingen' },
        ],
      },
      {
        label: 'Recepten',
        path: '/recepten',
        children: [
          { label: 'Recepten', path: '/recepten' },
          { label: 'Artikelen', path: '/artikelen' },
        ],
      },
      { label: 'Contact', path: '/contact' },
    ]

    return (
      <div
        css={[
          this.props.styles,
          {
            marginBottom: 25,
            [media.medium]: { marginBottom: 40 },
            [media.navigation]: { marginBottom: 100 },
          },
          this.props.background
            ? {
                background: `url(${this.props.background}) no-repeat`,
                backgroundPosition: '50% 50%',
                backgroundSize: 'cover',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                ':before': {
                  background: 'rgba(0, 0, 0, .15)',
                  content: `''`,
                  height: '100%',
                  position: 'absolute',
                  width: '100%',
                },
                ':after': {
                  background:
                    'linear-gradient(rgba(0, 0, 0, .45), transparent 50%)',
                  content: `''`,
                  height: '100%',
                  position: 'absolute',
                  width: '100%',
                },
              }
            : {},
        ]}
      >
        <NavigationBar useBorder={!this.props.background}>
          <Inner hasBackground={!!this.props.background}>
            <Logo>
              <Link to="/">
                <LogoImage color={this.props.background ? 'white' : null} />
              </Link>
            </Logo>
            {items.map((link, index) => (
              <RegularMenuItem
                key={link.path}
                order={index + 1}
                hasBackground={!!this.props.background}
              >
                <Link to={link.path}>{link.label}</Link>
                {link.children && this.renderChildren(link.children)}
              </RegularMenuItem>
            ))}
            <Toggle
              toggled={this.state.toggled}
              onClick={this.toggleNavigation}
              hasBackground={!!this.props.background}
            >
              <div />
              <div />
              <div />
            </Toggle>
            <ToggleMenu toggled={this.state.toggled}>
              {items.map(link => (
                <ToggleMenuItem key={link.path}>
                  <Link to={link.path} onClick={this.toggleNavigation}>
                    {link.label}
                  </Link>

                  {link.children && (
                    <div>
                      {link.children.map(child => (
                        <ToggleMenuItem
                          key={child.path}
                          onClick={this.toggleNavigation}
                        >
                          <Link to={child.path}>{child.label}</Link>
                        </ToggleMenuItem>
                      ))}
                    </div>
                  )}
                </ToggleMenuItem>
              ))}
            </ToggleMenu>
          </Inner>
        </NavigationBar>

        <div
          css={{
            display: 'flex',
            flexGrow: 1,
            zIndex: 1,
          }}
        >
          {this.props.children}
        </div>
      </div>
    )
  }

  renderChildren(children) {
    return (
      <Submenu>
        {children.map(link => (
          <Link key={link.path} to={link.path}>
            {link.label}
          </Link>
        ))}
      </Submenu>
    )
  }
}
